<template>
  <div>
    <partner-payouts-monthly-stats />
  </div>
</template>
<script>
import StatisticsAPI from '@/api/statistics'
import PartnerPayoutsMonthlyStats
from '@/views/statistics/affiliate-statistics/components/PartnerPayoutsMonthlyStats.vue'

export default {
  components: {
    PartnerPayoutsMonthlyStats,
  },
  data() {
    return {
      loading: false,
      salesChart: {
        discount: null,
        affiliate: null,
      },
      orders: [],
    }
  },
  methods: {
    onSelectDiscount(id) {
      this.salesChart.discount = id
      this.searchOrders()
    },
    onselectAffiliate(id) {
      this.salesChart.affiliate = id
      this.searchOrders()
    },
    searchOrders() {
      StatisticsAPI.getOrdersDataByAffAndDiscount(this.salesChart.discount, this.salesChart.affiliate)
        .then(response => {
          this.orders = response
        })
    },
    affiliateReduceFunc(affs) {
      return affs.map(item => ({ id: item[0].id, email: item[0].user.email }))
    },
  },
}
</script>
