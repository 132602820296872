<template>
  <b-overlay
    :show="loading"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card
      v-if="!loading || payouts"
      no-body
    >
      <b-card-header class="justify-content-between align-items-center">
        <b-card-title>
          <h4 class="vh-center">
            Partner payouts
          </h4>
        </b-card-title>

        <div class="col-md-5 col-sm-10 ml-auto">
          <entity-select
            v-model="partnerId"
            :filter="partnerFilter"
          />
        </div>
        <b-button
          v-b-tooltip.hover.top="'Force reload statistics'"
          class="ml-auto ml-md-1"
          variant="outline-primary"
          @click="loadData(true)"
        >
          <feather-icon icon="RepeatIcon" />
        </b-button>
      </b-card-header>

      <b-card-body class="pb-0 position-relative">

        <vue-apex-charts
          ref="chart"
          height="450px"
          :options="balanceChart.chartOptions"
          :series="balanceChart.series"
        />
        <b-overlay
          :show="false"
          no-wrap
          :variant="skin"
          blur="2px"
          opacity=".95"
        >
          <template #overlay>
            <div class="text-center">
              There is not payouts yet.
            </div>
          </template>
        </b-overlay>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay, VBPopover, VBTooltip, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { get } from 'vuex-pathify'
import { formatCurrency } from '@/plugins/formaters'
import StatisticsAPI from '@/api/statistics'
import EntitySelect from '@/components/ui/gqlforms/EntitySelect.vue'

export default {
  components: {
    EntitySelect,
    BButton,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      payouts: [],
      loading: false,
      partnerId: null,
      partnerFilter: {
        name: 'partner',
        label: 'Partner',
        filterable: {
          type: 'entity', queryType: 'partners', label: 'aff_custom_key', search: 'aff_custom_key', value: 'id',
        },
      },
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    balanceChart() {
      return {
        series: [
          {
            name: 'Profit',
            data: this.payouts.map(item => item.total_payout),
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
          },
          colors: [$themeColors.primary, $themeColors.info, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: '75%',
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: this.payouts.map(item => item.confirm_date),
            labels: {
              style: {
                colors: '#b9b9c3',
              },
            },
          },
          yaxis: [
            {
              labels: {
                style: {
                  colors: '#b9b9c3',
                },
              },
              opposite: true,
              title: {
                text: 'Total payouts',
              },
            },
          ],
          tooltip: {
            y: {
              show: true,
              formatter: value => formatCurrency(value, 'usd'),
            },
            x: {
              show: true,
            },
          },
        },
      }
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    formatCurrency,
    loadData(forceReload = false) {
      this.loading = true
      StatisticsAPI.getPartnerMonthlyPayouts(this.partnerId, forceReload).then(response => {
        this.payouts = response
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
